import { template as template_f4c1000f7b974924a11b427bf09ec1f4 } from "@ember/template-compiler";
import i18n from "discourse-common/helpers/i18n";
const GeneratedInviteLink = template_f4c1000f7b974924a11b427bf09ec1f4(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
