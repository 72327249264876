import { template as template_2aa591b1d5a84cc18cf247ec82fd052e } from "@ember/template-compiler";
const WelcomeHeader = template_2aa591b1d5a84cc18cf247ec82fd052e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
