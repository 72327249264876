import { template as template_10c2b7b72a1d4d02a177e0a3586de0b9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_10c2b7b72a1d4d02a177e0a3586de0b9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
