import { template as template_431cf6848b0245c09d7ad2bf7c01d0ac } from "@ember/template-compiler";
const FKText = template_431cf6848b0245c09d7ad2bf7c01d0ac(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
